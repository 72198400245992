import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import {
  ADeviceBasic,
  ADeviceDetailSublocationMachineWithMap,
} from "../../api/deviceAPI";
import { ADeviceDetailsWithSensorAlert } from "../../api/enviroboxAPI";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import { GetDeviceObj } from "../../types/endpoints/device/device";
import {
  EnergyMeterData,
  EnergyMeterReqObj,
} from "../../types/endpoints/energymeter/energymeter";

export const energymeterStoreAPI = createApi({
  reducerPath: "energymeterStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/dashboard/energymeter`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["EnergyMeterSummary", "DeviceList"],
  endpoints: (builder) => {
    return {
      getSummary: builder.query<EnergyMeterData, EnergyMeterReqObj>({
        query: (obj) => {
          return {
            url: "", // no additional param
            method: "POST",
            body: obj,
          };
        },
        // providesTags: ["EnergyMeterSummary"],
        providesTags: (_, __, obj) => {
          // @ts-ignore
          let id: string | undefined = obj.period ? obj.period : undefined;
          return ["EnergyMeterSummary", { type: "EnergyMeterSummary", id }];
        },
        transformResponse: (res: BackendDataShape<EnergyMeterData>) => res.data,
      }),
      getEnergyMeterDeviceList: builder.query<
        ADeviceDetailsWithSensorAlert[],
        GetDeviceObj
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/devicelist" + params;
          return {
            url,
            method: "GET",
          };
        },
        providesTags: ["DeviceList"],
        transformResponse: (
          res: BackendDataShape<ADeviceDetailsWithSensorAlert[]>,
        ) => res.data,
      }),
    };
  },
});

export const {
  useGetSummaryQuery,
  useGetEnergyMeterDeviceListQuery,
  util: { resetApiState: resetEnergymeterStoreAPI },
} = energymeterStoreAPI;
