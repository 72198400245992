import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { AMachine, MachineOEEObj } from "../../api/machineOEEAPI";

export interface machineOEEData {
  dataArr: AMachine[];
}

const initialState: machineOEEData = {
  dataArr: [],
};

export const machineOEESlice = createSlice({
  name: "MACHINE_OEE_SLICE",
  initialState,
  reducers: {
    storeMachineOEE: (state, payload: PayloadAction<AMachine[]>) => {
      state.dataArr = payload.payload;
    },
  },
});

export default machineOEESlice.reducer;
export const { storeMachineOEE } = machineOEESlice.actions;

// export const dashboardDetailStoreAPI = createApi({
//   reducerPath: "dashboardDetailStoreAPI",
//   baseQuery: fetchBaseQuery({
//     baseUrl: `${UsedAPI}/dashboard`,
//     prepareHeaders: (headers) => {
//       let token = CookieMonster.loadCookie("jwt");
//       headers.set("Authorization", `Bearer ${token}`);
//       return headers;
//     },
//   }),
//   tagTypes: ["DeviceDetailsData"],
//   endpoints: (builder) => {
//     return {
//       getDeviceDetails: builder.query<BackendDataShape<UsageData>, UsageObj>({
//         query: ({
//           limit,
//           aftertime,
//           beforetime,
//           deviceId,
//           sensorcode,
//         }: UsageObj) => {
//           console.log({ limit, aftertime, beforetime, deviceId, sensorcode });
//           return {
//             url: "/sensorlog/find",
//             method: "POST",
//             body: {
//               limit,
//               beforetime,
//               aftertime,
//               deviceId,
//               sensorcode,
//             },
//           };
//         },
//         providesTags: (result, error, id) => [
//           { type: "DeviceDetailsData", id },
//         ],
//       }),
//     };
//   },
// });

// export const {
//   useGetDeviceDetailsQuery,
//   // useGetDailyQuery,
//   // useGetWeeklyQuery,
//   // useGetMonthlyQuery,
//   // useGetDailyMutation,
//   // useGetMonthlyMutation,
//   // useGetWeeklyMutation,
// } = dashboardDetailStoreAPI;
