import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import userStore from "./userStore";
import sidebarStore from "./sidebarStore";
import dashboardDetailsStore from "./dashboardDetailStore";
import reportStore from "./reportStore";
import deviceStore from "./deviceStore";
import companyStore from "./companyStore";
import locationStore from "./locationStore";
import sensorStore from "./sensorStore";
import alertStore from "./alertStore";
import machineDashboardOEEStore from "./machineDashboardOEEStore";
import scheduleStore from "./scheduleStore";
import shiftStore from "./shiftStore";
import { dashboardEnviroboxStoreAPI } from "./dashboardEnviroboxStore/dashboardEnviroboxStoreAPI";
import { dashboardStoreAPI } from "./dashboardStore/dashboardStoreAPI";
import { alertStoreAPI } from "./alertStore/alertStoreAPI";
import { sensortypeStoreAPI } from "./sensorTypeStore/sensortypeStoreAPI";
import { companyStoreAPI } from "./companyStore/companyStoreAPI";
import { locationStoreAPI } from "./locationStore/locationStoreAPI";
import { gatewayStoreAPI } from "./gatewayStore/gatewayStoreAPI";
import { sensorStoreAPI } from "./sensorStore/sensorStoreAPI";
import { deviceStoreAPI } from "./deviceStore/deviceStoreAPI";
import { workorderStoreAPI } from "./workorderStore/workorderStoreAPI";
import { machineStoreAPI } from "./machineStore/machineStoreAPI";
import { productStoreAPI } from "./productStore/productStoreAPI";
import { dashboardOEEStoreAPI } from "./dashboardOEEStoreAPI/dashboardOEEStoreAPI";
import { devicetypeStoreAPI } from "./devicetypeStore/devicetypeStoreAPI";
import { shiftStoreAPI } from "./shiftStore/shiftStoreAPI";
import { machineOeeStoreAPI } from "./machineDashboardOEEStore/machineOEEStoreAPI";
import { energymeterStoreAPI } from "./energymeterStore/energymeterAPI";
import { notificationStoreAPI } from "./notificationStore/notificationStoreAPI";
// import { groupMachineStoreAPI } from "./machineStore/groupMachineStoreAPI";

export const store = configureStore({
  /**
   * @todo sort these reducer slices by alphabet, ascending.
   */
  reducer: {
    userStore,
    deviceStore,
    // gatewayStore,
    sidebarStore,
    companyStore,
    locationStore,
    dashboardDetailsStore,
    reportStore,
    sensorStore,
    alertStore,
    machineDashboardOEEStore,
    scheduleStore,
    shiftStore,
    [dashboardStoreAPI.reducerPath]: dashboardStoreAPI.reducer,
    [dashboardEnviroboxStoreAPI.reducerPath]:
      dashboardEnviroboxStoreAPI.reducer,
    [deviceStoreAPI.reducerPath]: deviceStoreAPI.reducer,
    [alertStoreAPI.reducerPath]: alertStoreAPI.reducer,
    [sensortypeStoreAPI.reducerPath]: sensortypeStoreAPI.reducer,
    [companyStoreAPI.reducerPath]: companyStoreAPI.reducer,
    [locationStoreAPI.reducerPath]: locationStoreAPI.reducer,
    [gatewayStoreAPI.reducerPath]: gatewayStoreAPI.reducer,
    [sensorStoreAPI.reducerPath]: sensorStoreAPI.reducer,
    [workorderStoreAPI.reducerPath]: workorderStoreAPI.reducer,
    [machineStoreAPI.reducerPath]: machineStoreAPI.reducer,
    [productStoreAPI.reducerPath]: productStoreAPI.reducer,
    [dashboardOEEStoreAPI.reducerPath]: dashboardOEEStoreAPI.reducer,
    [devicetypeStoreAPI.reducerPath]: devicetypeStoreAPI.reducer,
    [shiftStoreAPI.reducerPath]: shiftStoreAPI.reducer,
    [machineOeeStoreAPI.reducerPath]: machineOeeStoreAPI.reducer,
    [energymeterStoreAPI.reducerPath]: energymeterStoreAPI.reducer,
    [notificationStoreAPI.reducerPath]: notificationStoreAPI.reducer,
    // [groupMachineStoreAPI.reducerPath]: groupMachineStoreAPI.reducer,
  },
  middleware: (getMiddleWares) => {
    /**
     * @todo sort these middlewares by alphabet, ascending.
     */
    let middlewares = getMiddleWares().concat([
      dashboardStoreAPI.middleware,
      alertStoreAPI.middleware,
      companyStoreAPI.middleware,
      sensortypeStoreAPI.middleware,
      locationStoreAPI.middleware,
      gatewayStoreAPI.middleware,
      sensorStoreAPI.middleware,
      deviceStoreAPI.middleware,
      workorderStoreAPI.middleware,
      machineStoreAPI.middleware,
      productStoreAPI.middleware,
      dashboardOEEStoreAPI.middleware,
      devicetypeStoreAPI.middleware,
      dashboardEnviroboxStoreAPI.middleware,
      shiftStoreAPI.middleware,
      energymeterStoreAPI.middleware,
    ]);
    return middlewares;
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
