import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { ADeviceSensorRelation } from "../../types/endpoints/device/deviceRelation";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import {
  AlertDeviceRelation,
  AlertRelationObj,
} from "../../components/organism/Table/AlertTableExpansion";
import {
  CreateAlertObj,
  AlertObjWithData,
  GetAlertObj,
} from "../../types/endpoints/alert/alert";

export const alertStoreAPI = createApi({
  reducerPath: "alertStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/sensor/alert`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["AlertList", "AlertRelations", "RelationAlertsByID"],
  endpoints: (builder) => {
    return {
      getAlerts: builder.query<AlertObjWithData[], GetAlertObj>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find" + params;
          // console.log({ updateUrl: url });
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AlertObjWithData[]>) =>
          response.data,
        providesTags: ["AlertList"],
      }),
      getAlertsRelationDetails: builder.query<
        BackendDataShape<ADeviceSensorRelation[]>,
        { alertId: number }
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/findrelationdetails" + params;
          console.log({ url });
          return {
            url,
            method: "GET",
          };
        },
        providesTags: (res, error, { alertId }) => [
          { type: "AlertRelations", id: alertId },
        ],
      }),

      toggleAlertStatus: builder.mutation<
        BackendDataShape<string>,
        { alertId: number }
      >({
        query: ({ alertId }) => {
          return {
            url: "/status",
            method: "POST",
            body: { alertId },
          };
        },
        invalidatesTags: (_, __, { alertId }) => [
          { type: "AlertList", id: alertId },
        ],
      }),

      toggleEmailStatus: builder.mutation<
        BackendDataShape<string>,
        { alertId: number }
      >({
        query: ({ alertId }) => {
          return {
            url: "/emailstatus",
            method: "POST",
            body: { alertId },
          };
        },
        invalidatesTags: (_, __, { alertId }) => [
          { type: "AlertList", id: alertId },
        ],
      }),

      toggleWAStatus: builder.mutation<
        BackendDataShape<string>,
        { alertId: number }
      >({
        query: ({ alertId }) => {
          // console.log({ wa_wei: alertId });
          return {
            url: "/whatsappstatus",
            method: "POST",
            body: { alertId },
          };
        },
        invalidatesTags: (_, __, { alertId }) => [
          { type: "AlertList", id: alertId },
        ],
      }),

      createAlert: builder.mutation<
        BackendDataShape<{ id: number }[]>,
        CreateAlertObj
      >({
        query: (obj) => {
          return {
            url: "/create",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["AlertList"],
      }),

      updateAlert: builder.mutation<
        BackendDataShape<string>,
        {
          /**
           * gateway Id
           */
          id: number;
          data: Partial<CreateAlertObj>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/update/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["AlertList"],
      }),

      deleteAlert: builder.mutation<
        BackendDataShape<string>,
        {
          /**
           * gateway Id
           */
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["AlertList"],
      }),

      removeRelationAlert: builder.mutation<
        BackendDataShape<{ relationid: number }[]>,
        AlertRelationObj
      >({
        query: (obj) => {
          return {
            url: `/removerelationarray`,
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["AlertRelations", "AlertList"],
      }),

      getRelationAlertsByID: builder.query<
        BackendDataShape<AlertObjWithData[]>,
        { relationId: number }
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find/relation" + params;
          return {
            url,
            method: "GET",
          };
        },
        providesTags: (res, error, { relationId }) => [
          { type: "RelationAlertsByID", id: relationId },
        ],
      }),

      createRelationAlertArray: builder.mutation<
        BackendDataShape<{ relationid: number }[]>,
        AlertRelationObj
      >({
        query: (obj) => {
          return {
            url: "/addrelationarray",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["AlertRelations", "AlertList"],
      }),
    };
  },
});

export const {
  useGetAlertsQuery,
  useGetAlertsRelationDetailsQuery,
  useGetRelationAlertsByIDQuery,
  useToggleAlertStatusMutation,
  useToggleEmailStatusMutation,
  useToggleWAStatusMutation,
  useCreateRelationAlertArrayMutation,

  useCreateAlertMutation,
  useDeleteAlertMutation,
  useUpdateAlertMutation,
  useRemoveRelationAlertMutation,

  util: { resetApiState: resetAlertStoreAPI },
} = alertStoreAPI;
