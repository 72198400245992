import React from "react";
import toast from "react-hot-toast";
import CustomToast from "../Toast/CustomToast";
import { KnownUsertype } from "../../api/credentialsAPI/usertype";
import useUsertype from "../../hooks/auth/useUsertype";
import NoMatch from "../../pages/NoMatch";

interface Props {
  allowedUsers: KnownUsertype[];
}

/**
 *  We assume that the jwt was checked before
 */
const UserGate: React.FunctionComponent<Props> = ({
  children,
  allowedUsers,
}) => {
  let usertype = useUsertype();
  if (usertype && !!allowedUsers.find((user) => usertype === user)) {
    return <>{children}</>;
  } else {
    toast.custom(
      (t) => (
        <CustomToast
          t={t}
          headerText="Error!"
          description={"You dont have enough credential to access the page"}
          status="error"
        />
      ),
      { position: "top-right" },
    );
    return <NoMatch />;
  }
};

export default UserGate;
