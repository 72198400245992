import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { AEnviroboxBasic } from "../../api/enviroboxAPI";
import { convertToEncodedURL } from "../../assets/usefulFunctions";

export const dashboardEnviroboxStoreAPI = createApi({
  reducerPath: "dashbordEnviroboxStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/dashboard`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["EnviroBoxList"],
  endpoints: (builder) => {
    return {
      getEnvirobox: builder.query<AEnviroboxBasic, object>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          const url = "/envirobox" + params;
          return {
            url,
            method: "GET",
          };
        },
        providesTags: ["EnviroBoxList"],
        transformResponse: (response: BackendDataShape<AEnviroboxBasic>) => {
          return response.data;
        },
      }),
      getSensorLogEnvirobox: builder.mutation<
        BackendDataShape<string>,
        {
          deviceIds: Array<number>;
          period: string;
          sensorcode: Array<string>;
        }
      >({
        query: (obj) => {
          return {
            body: obj,
            url: `/sensorlog/envirobox`,
            method: "POST",
          };
        },
        invalidatesTags: ["EnviroBoxList"],
      }),
    };
  },
});

export const {
  useGetEnviroboxQuery,
  util: { resetApiState: resetDashboardEnviroboxAPI },
} = dashboardEnviroboxStoreAPI;
