import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "..";
import CookieMonster from "../../api/CookieMonster";
import { KnownUsertype } from "../../api/credentialsAPI/usertype";
import { ListOfRoutes } from "../../config/nav";

/**
 * This hook will check usertype in redux store first, if failed it will check Cookies.
 */
const useUsertype = () => {
  const usertype =
    useAppSelector(({ userStore }) => userStore.userData?.usertypeName) ||
    (CookieMonster.loadCookie("usertype") as KnownUsertype | undefined);
  // if (!usertype) {
  //   navigate(ListOfRoutes.loginpage, {
  //     state: {
  //       from: location.pathname,
  //     },
  //   });
  //   return "";
  // }
  return usertype;
};

/**
 *
 * @deprecated `navigate` inside this hook should be inside a `useEffect`
 */
export const useUsertypeWithSideEffect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const usertype = useUsertype();
  if (!usertype) {
    navigate(ListOfRoutes.loginpage, {
      state: {
        from: location.pathname,
      },
    });
    return "";
  }
  return usertype;
};

export const checkIfUserAllowed = (
  user: KnownUsertype,
  list?: string[],
): boolean => {
  if (list && list.length) {
    let found = list.find((val) => {
      return val === user;
    });
    return !!found;
  } else {
    return true;
  }
};

export default useUsertype;
