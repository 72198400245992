import React from "react";
import { Toaster } from "react-hot-toast";
import { HashRouter, Route, Routes, Outlet } from "react-router-dom";
import AuthHOC from "./components/auth/AuthHOC";
import UserGate from "./components/auth/UserGate";
import {
  DashboardRoutes,
  EnergyMeterRoutes,
  ListOfRoutes,
  ManagementRoutes,
  ReportRoutes,
} from "./config/nav";
import Layout from "./layout/Layout";
import NoMatch from "./pages/NoMatch";
import Redirector from "./pages/Redirector";

const LoginPage = React.lazy(() => import("./pages/LoginPage"));

// DASHBOARD PAGES
const EnergyMeterPage = React.lazy(
  () => import("./pages/DashboardPages/EnergyMeterDashboard/EnergyMeterPage"),
);
const EnergyMeterDetailPage = React.lazy(
  () =>
    import("./pages/DashboardPages/EnergyMeterDashboard/EnergyMeterDetailPage"),
);
const OverallWaterLevelDashboardPage = React.lazy(
  () => import("./pages/DashboardPages/OverallWaterLevelDashboard"),
);

const WaterLevelByLocationDashboardPage = React.lazy(
  () => import("./pages/DashboardPages/WaterLevelByLocationDashboard"),
);

const WaterLevelLocationById = React.lazy(
  () =>
    import(
      "./pages/DashboardPages/WaterLevelByLocationDashboard/DetailWaterLevelLocation/DetailWaterLevelLocation"
    ),
);

const EnviroboxPage = React.lazy(
  () => import("./pages/DashboardPages/EnviroboxDashboard/EnviroboxPage"),
);
const WeatherStationDashboardPage = React.lazy(
  () => import("./pages/DashboardPages/WeatherStationDashboard"),
);

const DetailEnviroboxPage = React.lazy(
  () =>
    import(
      "./pages/DashboardPages/EnviroboxDashboard/DetailEnviroboxPage/DetailEnviroboxPage"
    ),
);

const OEEPage = React.lazy(
  () => import("./pages/DashboardPages/OEEDashboard/OEEPage"),
);
// const MachinePage = React.lazy(() => import("./pages/MachinePage"));

const OEEMachineDetail = React.lazy(
  () => import("./pages/DashboardPages/OEEDashboard/OEEMachineDetail"),
);

// MANAGEMENT PAGES
const LocationPage = React.lazy(
  () => import("./pages/ManagementPages/LocationPage"),
);

const GatewaysPage = React.lazy(
  () => import("./pages/ManagementPages/GatewaysPage"),
);

const SensorTypePage = React.lazy(
  () => import("./pages/ManagementPages/SensorTypePage"),
);

const DevicesPage = React.lazy(
  () => import("./pages/ManagementPages/DevicesPage"),
);

const CompanyPage = React.lazy(
  () => import("./pages/ManagementPages/CompanyPage"),
);

const AlertPage = React.lazy(() => import("./pages/ManagementPages/AlertPage"));

const WorkOrderPage = React.lazy(
  () => import("./pages/ManagementPages/WorkOrderPage"),
);

const MachineManagementPage = React.lazy(
  () => import("./pages/ManagementPages/MachineManagementPage"),
);

// REPORT PAGES
const ReportPage = React.lazy(() => import("./pages/ReportPages/ReportPage"));

const SchedulePage = React.lazy(
  () => import("./pages/ReportPages/SchedulePage"),
);

// MACHINE PAGES
const MachinePage = React.lazy(
  () => import("./pages/MachineDashboardPages/MachinePage"),
);

// PRODUCT PAGES
const ProductPage = React.lazy(
  () => import("./pages/ManagementPages/ProductPage"),
);

const ShiftPage = React.lazy(() => import("./pages/ManagementPages/ShiftPage"));

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route
            path="/"
            element={
              <AuthHOC>
                <Layout />
              </AuthHOC>
            }
          >
            <Route index element={<Redirector />} />

            {/* DASHBOARD ROUTES */}
            <Route path={DashboardRoutes.energyMeter}>
              <Route index element={<EnergyMeterPage />} />
              <Route
                path={EnergyMeterRoutes.energyMeterDetail}
                element={<EnergyMeterDetailPage />}
              >
                <Route path=":deviceId" element={<EnergyMeterDetailPage />} />
              </Route>
            </Route>

            <Route path={DashboardRoutes.oee} element={<OEEPage />} />
            <Route
              path={DashboardRoutes.overallWaterLevel}
              element={<OverallWaterLevelDashboardPage />}
            />
            <Route
              path={DashboardRoutes.weatherStation}
              element={<WeatherStationDashboardPage />}
            />
            <Route
              path={DashboardRoutes.waterLevelByLocation}
              element={<WaterLevelByLocationDashboardPage />}
            />
            <Route
              path={DashboardRoutes.waterLevelLocationById(":locationId")}
              element={<WaterLevelLocationById />}
            />
            <Route
              path={DashboardRoutes.oeeMachines}
              element={<OEEMachineDetail />}
            />
            <Route
              path={DashboardRoutes.enviroboxDetailWithId(":deviceId")}
              element={<DetailEnviroboxPage />}
            />
            <Route
              path={DashboardRoutes.envirobox}
              element={<EnviroboxPage />}
            />

            {/* MANAGEMENT ROUTES */}
            <Route path={ListOfRoutes.management}>
              <Route
                path={ManagementRoutes.locations}
                element={<LocationPage />}
              />
              <Route
                path={ManagementRoutes.company}
                element={<CompanyPage />}
              />
              <Route
                path={ManagementRoutes.gateway}
                element={
                  <UserGate allowedUsers={["superadmin"]}>
                    <GatewaysPage />
                  </UserGate>
                }
              />
              <Route
                path={ManagementRoutes.sensortypes}
                element={<SensorTypePage />}
              />
              <Route
                path={ManagementRoutes.devices}
                element={<DevicesPage />}
              />
              <Route path={ManagementRoutes.alerts} element={<AlertPage />} />
              <Route
                path={ManagementRoutes.workorder}
                element={<WorkOrderPage />}
              />

              <Route path={ManagementRoutes.shift} element={<ShiftPage />} />

              <Route
                path={ManagementRoutes.machine}
                element={<MachineManagementPage />}
              />

              <Route
                path={ManagementRoutes.product}
                element={<ProductPage />}
              />
            </Route>

            {/* REPORT ROUTES */}
            <Route path={ListOfRoutes.reports}>
              <Route index element={<ReportPage />} />
              <Route path={ReportRoutes.schedule} element={<SchedulePage />} />
            </Route>
          </Route>

          <Route
            path={ListOfRoutes.machineDetails}
            element={
              <AuthHOC>
                <Outlet />
              </AuthHOC>
            }
          >
            <Route
              path=":id"
              element={
                <UserGate allowedUsers={["operator"]}>
                  <React.Suspense fallback={<>loading...</>}>
                    <MachinePage />
                  </React.Suspense>
                </UserGate>
              }
            />
          </Route>
          <Route
            path={ListOfRoutes.loginpage}
            element={
              <React.Suspense fallback={<>loading...</>}>
                <LoginPage />
              </React.Suspense>
            }
          />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </HashRouter>
      <Toaster />
    </div>
  );
}

export default App;
