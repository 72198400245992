import axios from "axios";
import { MockUserData } from "../../mocks";
import { BackendDataShape, CredsLinks } from "../config";
import CookieMonster from "../CookieMonster";
import { KnownUsertype } from "./usertype";

export interface LoginObj {
  username: string;
  password: string;
  remember?: 1 | 0;
}

export interface UserData {
  user_id: number;
  username: string;
  usertypeId: number;
  usertypeName: KnownUsertype;
  companyId: number;
  companyName: string;
  /**
   * the token
   */
  jwt: string;
  // /**
  //  * token max age in s
  //  */
  // max_age_s: 86400;

  iat: number;
  /**
   * expire in second
   */
  exp: number;
}

export type UserDataWOToken = Omit<UserData, "jwt" | "iat" | "exp">;

let sample: UserDataWOToken;
let user: UserData;

export function letUserLogin(obj?: LoginObj) {
  const url = `${CredsLinks.login}`;
  const payload = obj
    ? obj
    : {
        jwt: CookieMonster.loadCookie("jwt"),
      };
  return axios.post<BackendDataShape<UserData>>(url, payload);
}

export async function MockLetUserLogin() {
  return MockUserData;
}

export function letUserLogout() {
  const url = `${CredsLinks.logout}`;
  return axios.get<BackendDataShape<string>>(url);
}
