import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "..";
import CookieMonster from "../../api/CookieMonster";
import { letUserLogin, LoginObj } from "../../api/credentialsAPI";
import { ListOfRoutes } from "../../config/nav";
import { resetAlertStore } from "../../store/alertStore";
import { resetAlertStoreAPI } from "../../store/alertStore/alertStoreAPI";
import { resetCompanyStore } from "../../store/companyStore";
import { resetCompanyStoreAPI } from "../../store/companyStore/companyStoreAPI";
import { resetDashboardDetailStore } from "../../store/dashboardDetailStore";
import { resetDashboardEnviroboxAPI } from "../../store/dashboardEnviroboxStore/dashboardEnviroboxStoreAPI";
import { resetDashboardOEEStoreAPI } from "../../store/dashboardOEEStoreAPI/dashboardOEEStoreAPI";
import { resetDashboardStoreAPI } from "../../store/dashboardStore/dashboardStoreAPI";
import { resetDeviceGroupStoreAPI } from "../../store/deviceGroupStore/groupStoreAPI";
import { resetDeviceStore } from "../../store/deviceStore";
import { resetDeviceStoreAPI } from "../../store/deviceStore/deviceStoreAPI";
import { resetDevicetypeStoreAPI } from "../../store/devicetypeStore/devicetypeStoreAPI";
import { resetGatewayStoreAPI } from "../../store/gatewayStore/gatewayStoreAPI";
import { resetLocationStoreAPI } from "../../store/locationStore/locationStoreAPI";
import { resetGroupMachineStoreAPI } from "../../store/machineStore/groupMachineStoreAPI";
import { resetMachineStoreAPI } from "../../store/machineStore/machineStoreAPI";
import { resetProductStoreAPI } from "../../store/productStore/productStoreAPI";
import { resetReportStore } from "../../store/reportStore";
import { resetSensorStore } from "../../store/sensorStore";
import { resetSensorStoreAPI } from "../../store/sensorStore/sensorStoreAPI";
import { resetSensortypeStoreAPI } from "../../store/sensorTypeStore/sensortypeStoreAPI";
import { resetShiftStore } from "../../store/shiftStore";
import { resetShiftStoreAPI } from "../../store/shiftStore/shiftStoreAPI";
import { removeUserData, storeUserData } from "../../store/userStore";
import { resetWorkorderStoreAPI } from "../../store/workorderStore/workorderStoreAPI";
import { resetMachineOEEStoreAPI } from "../../store/machineDashboardOEEStore/machineOEEStoreAPI";
import { resetEnergymeterStoreAPI } from "../../store/energymeterStore/energymeterAPI";
import { resetDeviceSensorRelationAPI } from "../../store/deviceStore/deviceSensorRelationStoreAPI";
import { resetNotificationAPI } from "../../store/notificationStore/notificationStoreAPI";


export const useClearAllCache = () => {
  const dispatch = useAppDispatch();

  return () => {
    dispatch(resetAlertStore());
    dispatch(resetAlertStoreAPI());

    dispatch(resetCompanyStore());
    dispatch(resetCompanyStoreAPI());

    dispatch(resetDashboardDetailStore());
    dispatch(resetDashboardStoreAPI());

    dispatch(resetDeviceStore());
    dispatch(resetDeviceStoreAPI());

    dispatch(resetDeviceSensorRelationAPI());

    dispatch(resetDeviceGroupStoreAPI());

    dispatch(resetEnergymeterStoreAPI());

    dispatch(resetGatewayStoreAPI());

    dispatch(resetLocationStoreAPI());
    dispatch(resetLocationStoreAPI());

    dispatch(resetMachineStoreAPI());

    dispatch(resetProductStoreAPI());

    dispatch(resetReportStore());

    dispatch(resetSensorStore());
    dispatch(resetSensorStoreAPI());

    dispatch(resetSensortypeStoreAPI());

    dispatch(resetWorkorderStoreAPI());
    dispatch(resetGroupMachineStoreAPI());

    dispatch(resetDashboardOEEStoreAPI());

    dispatch(resetDevicetypeStoreAPI());

    dispatch(resetDashboardEnviroboxAPI());

    dispatch(resetShiftStore());
    dispatch(resetShiftStoreAPI());
    dispatch(resetMachineOEEStoreAPI());

    dispatch(resetNotificationAPI());
  };
};

export const useLogout = () => {
  const clearAllCache = useClearAllCache();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return () => {
    CookieMonster.removeAllTheseCookies(["usertype", "jwt"]);
    clearAllCache();
    dispatch(removeUserData());
    navigate(ListOfRoutes.loginpage);
  };
};

export const useLogin = () => {
  const dispatch = useAppDispatch();

  return async (obj?: LoginObj) => {
    const {
      data: { data },
    } = await letUserLogin(obj);
    const { jwt, exp, iat, ...rest } = data;

    CookieMonster.saveAllTheseCookies(
      {
        jwt,
        usertype: rest.usertypeName,
      },
      { expires: new Date(exp * 1000) },
    );

    dispatch(storeUserData(rest));
  };
};
