import { ENDPOINT_DEV, ENDPOINT_LOCAL } from "./apiEndpoints";
import { AENVType } from "./envItems";
export let UsedAPI = "";
export let BasicURL = "";
switch (process.env.REACT_APP_ENV as AENVType) {
  case "local":
    BasicURL = ENDPOINT_LOCAL;
    break;
  case "prod":
  case "dev":
  default:
    BasicURL = ENDPOINT_DEV;
    break;
}
UsedAPI = BasicURL + "/api";

export const CredsLinks = {
  login: `${UsedAPI}/user/login`,
  logout: `${UsedAPI}/user/logout`,
} as const;

export const UsertypeLinks = {
  find: `${UsedAPI}/usertype/find`,
} as const;

export const NotificationLinks = {
  list: `${UsedAPI}/notification/list`,
} as const;

export const GatewayLinks = {
  create: `${UsedAPI}/gateway/create`,
  find: `${UsedAPI}/gateway/find`,
  update: `${UsedAPI}/gateway/update`,
  delete: `${UsedAPI}/gateway/delete`,

  all_gateways: `${UsedAPI}/report/dashboard/wika/summary`,
  current_sensor_value: `${UsedAPI}/report/dashboard/wika/sensorvalue`,
  historical_sensor_value: `${UsedAPI}/report/dashboard/wika/historicalsensorvalue`,
  device_detail: `${UsedAPI}/report/dashboard/wika/detail`,
} as const;

export const CompanyLinks = {
  create: `${UsedAPI}/company/create`,
  find: `${UsedAPI}/company/find`,
  update: `${UsedAPI}/company/update`,
  delete: `${UsedAPI}/company/delete`,
} as const;

export const LocationLinks = {
  create: `${UsedAPI}/location/create`,
  find: `${UsedAPI}/location/find`,
  update: `${UsedAPI}/location/update`,
  delete: `${UsedAPI}/location/delete`,
} as const;

export const DeviceLinks = {
  create: `${UsedAPI}/device/create`,
  find: `${UsedAPI}/device/find`,
  update: `${UsedAPI}/device/update`,
  delete: `${UsedAPI}/device/delete`,

  create_relation: `${UsedAPI}/device/addsensor`,
  find_relation: `${UsedAPI}/device/findrelation`,
  update_relation: `${UsedAPI}/device/updaterelation`,
  delete_relation: `${UsedAPI}/device/deleterelation`,
  create_sensor_withAlert: `${UsedAPI}/device/addsensorwithalert`,
} as const;

export const SensorLinks = {
  create: `${UsedAPI}/sensor/create`,
  find: `${UsedAPI}/sensor/find`,
  update: `${UsedAPI}/sensor/update`,
  delete: `${UsedAPI}/sensor/delete`,
} as const;

export const SensorTypeLinks = {
  create: `${UsedAPI}/sensortype/create`,
  find: `${UsedAPI}/sensortype/find`,
  update: `${UsedAPI}/sensortype/update`,
  delete: `${UsedAPI}/sensortype/delete`,
} as const;

export const AlertLinks = {
  create: `${UsedAPI}/sensor/alert/create`,
  find: `${UsedAPI}/sensor/alert/find`,
  update: `${UsedAPI}/sensor/alert/update`,
  delete: `${UsedAPI}/sensor/alert/delete`,

  find_relation: `${UsedAPI}/sensor/alert/findrelation`,
  add_relation: `${UsedAPI}/sensor/alert/addrelation`,
  remove_relation: `${UsedAPI}/sensor/alert/removerelation`,

  toggle_alert: `${UsedAPI}/sensor/alert/status`,
  toggle_email: `${UsedAPI}/sensor/alert/emailstatus`,
  toggle_wa: `${UsedAPI}/sensor/alert/whatsappstatus`,
} as const;

export const DashboardLinks = {
  usage: `${UsedAPI}/dashboard/usage`,
  sensorlog: `${UsedAPI}/dashboard/sensorlog/find`,
} as const;

export const MachineLinks = {
  create: `${UsedAPI}/oee/machine/create`,
  find: `${UsedAPI}/oee/machine/find`,
  groupFind: `${UsedAPI}/oee/machine/group/find`,
  update: `${UsedAPI}/oee/machine/update`,
  delete: `${UsedAPI}/oee/machine/delete`,
} as const;

export const ProductLinks = {
  create: `${UsedAPI}/oee/product/create`,
  find: `${UsedAPI}/oee/product/find`,
  update: `${UsedAPI}/oee/product/update`,
  delete: `${UsedAPI}/oee/product/delete`,
} as const;

export const WorkOrderLinks = {
  create: `${UsedAPI}/oee/workorder/create`,
  find: `${UsedAPI}/oee/workorder/find`,
  update: `${UsedAPI}/oee/workorder/update`,
  delete: `${UsedAPI}/oee/workorder/delete`,
} as const;

export const ScheduleLinks = {
  create: `${UsedAPI}/schedulereport/create`,
  find: `${UsedAPI}/schedulereport/find`,
  delete: `${UsedAPI}/schedulereport/delete`,
} as const;

export const OeeLinks = {
  find_by_location: `${UsedAPI}/oee/location`,
  find_by_group_machine: `${UsedAPI}/oee/groupmachine`,
  find_by_product: `${UsedAPI}/oee/product`,
  find_by_overall: `${UsedAPI}/oee/overall`,
  find_by_shift: `${UsedAPI}/oee/shift`,
} as const;

export interface BackendDataShape<D> {
  status: string;
  data: D;
}
