import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnUsageData } from "../../api/dashboardDetailAPI";

export interface ReportState {
  logArr: AnUsageData[];
}

const initialState: ReportState = {
  logArr: [],
};

export const reportSlice = createSlice({
  name: "REPORT_SLICE",
  initialState,
  reducers: {
    storeReport: (state, payload: PayloadAction<AnUsageData[]>) => {
      state.logArr = payload.payload;
    },
    clearState: () => initialState,
    // clearState: (state) => {
    //   Object.assign(state, initialState);
    // },
  },
});

export default reportSlice.reducer;
export const { storeReport, clearState: resetReportStore } =
  reportSlice.actions;
