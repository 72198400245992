import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AMachine, MachineObj } from "../../api/machineAPI";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { convertToEncodedURL } from "../../assets/usefulFunctions";

export const machineStoreAPI = createApi({
  reducerPath: "machineStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/oee/machine`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["MachineList", "GroupMachineList"],
  endpoints: (builder) => {
    return {
      getMachineList: builder.query<AMachine[], Partial<MachineObj>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AMachine[]>) =>
          response.data,
        providesTags: ["MachineList"],
      }),

      createMachine: builder.mutation<BackendDataShape<AMachine>, MachineObj>({
        query: (obj) => {
          return {
            url: "/create",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["MachineList"],
      }),

      updateMachine: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<MachineObj>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/update/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["MachineList"],
      }),

      deleteMachine: builder.mutation<BackendDataShape<string>, { id: number }>(
        {
          query: ({ id }) => {
            return {
              url: `/delete/${id}`,
              method: "DELETE",
            };
          },
          invalidatesTags: ["MachineList"],
        },
      ),
    };
  },
});

export const {
  useGetMachineListQuery,
  useCreateMachineMutation,
  useUpdateMachineMutation,
  useDeleteMachineMutation,
  util: { resetApiState: resetMachineStoreAPI },
} = machineStoreAPI;
