import { BackendDataShape } from "../../api/config";
import {
  ADeviceSensorRelation,
  GetDeviceSensorRelationObj,
} from "../../types/endpoints/device/deviceRelation";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import { deviceStoreAPI } from "./deviceStoreAPI";

export const deviceSensorRelationAPI = deviceStoreAPI.injectEndpoints({
  endpoints: (builder) => {
    return {
      getDeviceRelation: builder.query<
        ADeviceSensorRelation[],
        GetDeviceSensorRelationObj
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/findrelation" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<ADeviceSensorRelation[]>,
        ) => response.data,
        providesTags: ["DeviceRelationList"],
      }),
      createSensorRelationWithAlert: builder.mutation<
        BackendDataShape<string[]>,
        {
          deviceId: number;
          sensorId: number;
          alertIds: number[];
        }
      >({
        query: (obj) => {
          let url = "/addsensorwithalert";
          return {
            url,
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["DeviceRelationList"],
      }),
      updateSensorRelationWithAlert: builder.mutation<
        BackendDataShape<string[]>,
        {
          deviceId: number;
          sensorTypeId: number;
          sensorId: number;
          alertIds: number[];
        }
      >({
        query: ({ sensorTypeId, ...rest }) => {
          let url = `/updaterelationwithalert/${sensorTypeId}`;
          return {
            url,
            method: "PATCH",
            body: { ...rest },
          };
        },
        invalidatesTags: ["DeviceRelationList"],
      }),
      updateSensorRelation: builder.mutation<
        BackendDataShape<string[]>,
        {
          sensorRelationTypeId: number;
          data: Partial<GetDeviceSensorRelationObj>;
        }
      >({
        query: ({ data, sensorRelationTypeId }) => {
          return {
            url: `/updaterelation/${sensorRelationTypeId}`,
            method: "PATCH",
            body: data,
          };
        },
        invalidatesTags: ["DeviceRelationList"],
      }),
      deleteDeviceSensorRelation: builder.mutation<
        BackendDataShape<string>,
        {
          /**
           * device Id
           */
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/deleterelation/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["DeviceRelationList"],
      }),
    };
  },
});

export const {
  useGetDeviceRelationQuery,
  useCreateSensorRelationWithAlertMutation,
  useUpdateSensorRelationMutation,
  useUpdateSensorRelationWithAlertMutation,
  useDeleteDeviceSensorRelationMutation,
  util: { resetApiState: resetDeviceSensorRelationAPI },
} = deviceSensorRelationAPI;
