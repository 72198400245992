export const ListOfRoutes = {
  loginpage: "/login",
  dashboard: "/dashboard",
  management: "/management",
  reports: "/reports",
  schedule: "/schedule",

  /**
   * Dedicated machine OEE, used by operator
   */
  machineDetails: "/machine",
} as const;

export const DashboardRoutes = {
  /**
   * Energy meter dashboard
   */
  energyMeter: `${ListOfRoutes.dashboard}/energymeter`,
  /**
   * Energy meter dashboard devices. consist of detail data of each energy meter devices
   */
  energyMeterDetail: `${ListOfRoutes.dashboard}/emdevices`,
  energyMeterDetailWithId: `${ListOfRoutes.dashboard}/emdevices/:deviceId`,

  /**
   * OEE Dashboard.
   */
  oee: `${ListOfRoutes.dashboard}/oee`,
  /**
   * OEE Machine detail.
   */
  oeeMachines: `${ListOfRoutes.dashboard}/oee/machines`,
  /**
   * Water Level Dashboard
   */
  overallWaterLevel: `${ListOfRoutes.dashboard}/waterlevel`,
  /**
   * List Water Levels by Location
   */
  waterLevelByLocation: `${ListOfRoutes.dashboard}/waterlevel/location`,
  /**
   * List Water Levels Detail
   */
  waterLevelLocationById: (id: string | number) => {
    return `${ListOfRoutes.dashboard}/waterlevel/location/${id}`;
  },
  /**
   * Envirobox Dashboard.
   */
  envirobox: `${ListOfRoutes.dashboard}/envirobox`,
  /**
   * Envirobox Dashboard Detail.
   */
  enviroboxDetailWithId: (deviceId: string | number) => {
    return `${ListOfRoutes.dashboard}/envirobox/${deviceId}`;
  },
  /**
   * Weather Station Dashboard
   */
  weatherStation: `${ListOfRoutes.dashboard}/weatherstation`,
} as const;

export const EnergyMeterRoutes = {
  /**
   * Energy meter dashboard
   */
  energyMeter: `${ListOfRoutes.dashboard}/energymeter`,
  /**
   * Energy meter dashboard devices. consist of detail data of each energy meter devices
   */
  energyMeterDetail: `${ListOfRoutes.dashboard}/energymeter/devices`,
  energyMeterDetailWithId: `${ListOfRoutes.dashboard}/energymeter/devices/:deviceId`,
} as const;

export const ManagementRoutes = {
  locations: `${ListOfRoutes.management}/locations`,
  company: `${ListOfRoutes.management}/company`,
  gateway: `${ListOfRoutes.management}/gateway`,
  sensortypes: `${ListOfRoutes.management}/sensortypes`,
  devices: `${ListOfRoutes.management}/devices`,
  alerts: `${ListOfRoutes.management}/alerts`,
  workorder: `${ListOfRoutes.management}/workorder`,
  machine: `${ListOfRoutes.management}/machine`,
  product: `${ListOfRoutes.management}/product`,
  shift: `${ListOfRoutes.management}/shift`,
} as const;

export const ReportRoutes = {
  reports: `${ListOfRoutes.reports}`,
  schedule: `${ListOfRoutes.reports}/schedulereport`,
} as const;
