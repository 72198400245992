import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { AMachineGroup } from "../../api/dashboardOEEMachineGroupAPI";
import {
  AWorkOrderLocationObj,
  WorkOrderLocationObj,
} from "../../api/dashboardOEELocationAPI";
import { OEEOverallObj } from "../../api/dashboardOEEOverallAPI";
import { OEEProductObj } from "../../api/dashboardOEEProductAPI";
import { AOEEShift } from "../../api/dashboardOEEShiftAPI";
import { convertToEncodedURL } from "../../assets/usefulFunctions";

/**
 * WIP
 */
export const dashboardOEEStoreAPI = createApi({
  reducerPath: "dashboardOEEStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/oee`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: [
    "GroupMachine",
    "OeeLocation",
    "OeeOverall",
    "OeeProduct",
    "OeeShift",
  ],
  endpoints: (builder) => {
    return {
      getFindOEEMachine: builder.query<AMachineGroup[], Partial<AMachineGroup>>(
        {
          query: (obj) => {
            let params = "";
            if (obj) {
              params = convertToEncodedURL(obj);
              params = params.length ? "?" + params : "";
            }
            const url = "/groupmachine" + params;
            return {
              url,
              method: "GET",
            };
          },
          transformResponse: (response: BackendDataShape<AMachineGroup[]>) => {
            return response.data;
          },
          providesTags: ["GroupMachine"],
        },
      ),
      getFindOEEByLocation: builder.query<
        AWorkOrderLocationObj[],
        Partial<WorkOrderLocationObj>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          const url = "/location" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<AWorkOrderLocationObj[]>,
        ) => {
          return response.data;
        },
        providesTags: ["OeeLocation"],
      }),
      getFindOeeOverall: builder.query<OEEOverallObj, Partial<OEEOverallObj>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          const url = "/overall" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<OEEOverallObj>) => {
          return response.data;
        },
        providesTags: ["OeeOverall"],
      }),
      getFindOeeProduct: builder.query<OEEProductObj[], Partial<OEEProductObj>>(
        {
          query: (obj) => {
            let params = "";
            if (obj) {
              params = convertToEncodedURL(obj);
              params = params.length ? "?" + params : "";
            }
            const url = "/product" + params;
            return {
              url,
              method: "GET",
            };
          },
          transformResponse: (response: BackendDataShape<OEEProductObj[]>) => {
            return response.data;
          },
          providesTags: ["OeeProduct"],
        },
      ),
      getFindOeeShift: builder.query<AOEEShift[], Partial<AOEEShift>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          const url = "/shift" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AOEEShift[]>) => {
          return response.data;
        },
        providesTags: ["OeeShift"],
      }),
    };
  },
});

export const {
  useGetFindOEEMachineQuery,
  useGetFindOEEByLocationQuery,
  useGetFindOeeOverallQuery,
  useGetFindOeeProductQuery,
  useGetFindOeeShiftQuery,
  util: { resetApiState: resetDashboardOEEStoreAPI },
} = dashboardOEEStoreAPI;
