import React, { Suspense } from "react";
import { createUseStyles } from "react-jss";
import { Outlet } from "react-router";
import { BasicTheme } from "../assets/theme";
import Sidebar from "../components/Sidebar/Sidebar";

interface Props {}

const useStyles = createUseStyles((theme: BasicTheme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "row",
      minHeight: "100vh",
    },
    sidebar: {
      flexBasis: "230px",
      maxHeight: "100vh",
      // backgroundColor: "green",
      display: "flex",
    },
    outlet: {
      flex: 1,
      // padding: "0 1rem",
      backgroundColor: theme.backgroundColor,
      maxHeight: "100vh",
      overflowY: "auto",
      // backgroundColor: "red",
    },
  };
});

const Layout: React.FunctionComponent<Props> = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Sidebar />
      <div className={classes.outlet}>
        <Suspense fallback={<>Loading...</>}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
};

export default Layout;
