import React from "react";
import { Navigate } from "react-router-dom";
import { KnownUsertype } from "../api/credentialsAPI/usertype";
import { DashboardRoutes, ListOfRoutes, ManagementRoutes } from "../config/nav";
import useUsertype, {
  useUsertypeWithSideEffect,
} from "../hooks/auth/useUsertype";

interface Props {}

const Redirector: React.FunctionComponent<Props> = (props: Props) => {
  const to = useDefaultRoute();
  return <Navigate replace to={to} />;
};

export default Redirector;

export const getDefaultRoute = (usertype?: KnownUsertype) => {
  switch (usertype) {
    case "superadmin":
      return ManagementRoutes.company;
    case "operator":
    case "user":
      return DashboardRoutes.energyMeter;
    default:
      return ListOfRoutes.loginpage;
  }
};

export const useDefaultRoute = () => {
  const usertype = useUsertype();
  return React.useMemo(() => {
    return getDefaultRoute(usertype);
  }, [usertype]);
};
