import { BackendDataShape } from "../../api/config";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import {
  AGroupMachinArray,
  AGroupMachine,
  AGroupManage,
  GroupMachineObj,
} from "../../api/groupMachineAPI";
import { machineStoreAPI } from "./machineStoreAPI";

export const groupMachineStoreAPI = machineStoreAPI.injectEndpoints({
  endpoints: (builder) => {
    return {
      getGroupMachineList: builder.query<
        AGroupMachine[],
        Partial<GroupMachineObj>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/group/find" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AGroupMachine[]>) =>
          response.data,
        providesTags: ["GroupMachineList"],
      }),

      createGroupMachine: builder.mutation<
        BackendDataShape<AGroupMachine>,
        GroupMachineObj
      >({
        query: (obj) => {
          return {
            url: "/group/create",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["GroupMachineList"],
      }),

      updateGroupMachine: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<GroupMachineObj>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/group/update/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["GroupMachineList"],
      }),

      deleteGroupMachine: builder.mutation<
        BackendDataShape<string>,
        { id: number }
      >({
        query: ({ id }) => {
          return {
            url: `/group/delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["GroupMachineList"],
      }),

      manageGroupMachine: builder.mutation<
        BackendDataShape<AGroupMachine>,
        AGroupManage
      >({
        query: (obj) => {
          return {
            url: `/group/manage`,
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["MachineList", "GroupMachineList"],
      }),

      addGroupMachineArray: builder.mutation<
        BackendDataShape<AGroupMachine>,
        AGroupMachinArray
      >({
        query: (obj) => {
          return {
            url: `/group/addmachinearray`,
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["MachineList"],
      }),
      deleteGroupMachineArray: builder.mutation<
        BackendDataShape<AGroupMachine>,
        AGroupMachinArray
      >({
        query: (obj) => {
          return {
            url: `/group/removemachinearray`,
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["MachineList"],
      }),
    };
  },
});

export const {
  useGetGroupMachineListQuery,
  useCreateGroupMachineMutation,
  useUpdateGroupMachineMutation,
  useDeleteGroupMachineMutation,
  useManageGroupMachineMutation,
  useAddGroupMachineArrayMutation,
  useDeleteGroupMachineArrayMutation,
  util: { resetApiState: resetGroupMachineStoreAPI },
} = groupMachineStoreAPI;
