import React from "react";
import { AiOutlineQrcode } from "react-icons/ai";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { RiComputerLine } from "react-icons/ri";
import {
  rapidsense_long,
  rapidsense_notext,
  logo_datacakraWhite,
} from "../../assets/images";
import { BasicTheme, widthBreakpoints } from "../../assets/theme";
import {
  DashboardRoutes,
  ListOfRoutes,
  ManagementRoutes,
} from "../../config/nav";
import { useAppDispatch, useAppSelector } from "../../hooks";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { toggleSidebar } from "../../store/sidebarStore";
import SidebarItem, { Props as SidebarItemProps } from "./SidebarItem";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { useLogin } from "../../hooks/auth";
import useSidebarStyles from "./style";
import { useTheme } from "react-jss";
import styled from "styled-components";

interface Props {}

const StyledNavigationItemWrapper = styled.div`
  flex: 1;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border: 3px solid #1c1c24;
  }
  overflow-y: auto;
`;

const nav: SidebarItemProps[] = [
  {
    Icon: RiComputerLine,
    parentURL: ListOfRoutes.dashboard,
    allowedUsers: ["user", "operator"],
    title: "Dashboard",
    kiddos: [
      {
        title: "Energy Meter",
        shrinkTitle: "EM",
        kiddoURL: DashboardRoutes.energyMeter,
      },
      {
        title: "OEE",
        shrinkTitle: "OEE",
        kiddoURL: DashboardRoutes.oee,
      },
      // {
      //   title: "Weather Station",
      //   shrinkTitle: "WS",
      //   kiddoURL: DashboardRoutes.weatherStation,
      // },
      {
        title: "Water Level",
        shrinkTitle: "WL",
        kiddoURL: DashboardRoutes.overallWaterLevel,
      },
      {
        title: "Envirobox",
        shrinkTitle: "EV",
        kiddoURL: DashboardRoutes.envirobox,
      },
    ],
  },
  {
    Icon: AiOutlineQrcode,
    parentURL: ListOfRoutes.management,
    title: "Management",
    kiddos: [
      {
        title: "Companies",
        shrinkTitle: "Cm",
        allowedUsers: ["superadmin"],
        kiddoURL: ManagementRoutes.company,
      },
      {
        title: "Gateways",
        shrinkTitle: "Gt",
        allowedUsers: ["superadmin"],
        kiddoURL: ManagementRoutes.gateway,
      },
      {
        title: "Locations",
        shrinkTitle: "Lc",
        allowedUsers: ["superadmin", "user"],
        kiddoURL: ManagementRoutes.locations,
      },
      {
        title: "Sensor Types",
        allowedUsers: ["superadmin"],
        shrinkTitle: "ST",
        kiddoURL: ManagementRoutes.sensortypes,
      },
      {
        title: "Devices",
        shrinkTitle: "Dv",
        allowedUsers: ["superadmin", "user"],
        kiddoURL: ManagementRoutes.devices,
      },
      {
        title: "Alerts",
        shrinkTitle: "Al",
        kiddoURL: ManagementRoutes.alerts,
      },
      {
        title: "Machine",
        shrinkTitle: "Mc",
        allowedUsers: ["user", "superadmin"],
        kiddoURL: ManagementRoutes.machine,
      },
      {
        title: "Product",
        shrinkTitle: "Pd",
        allowedUsers: ["user", "superadmin"],
        kiddoURL: ManagementRoutes.product,
      },
      {
        title: "Work Order",
        shrinkTitle: "WO",
        allowedUsers: ["user", "operator"],
        kiddoURL: ManagementRoutes.workorder,
      },
      {
        title: "Shift",
        shrinkTitle: "SF",
        allowedUsers: ["user"],
        kiddoURL: ManagementRoutes.shift,
      },
    ],
  },
  {
    Icon: HiOutlineDocumentReport,
    parentURL: ListOfRoutes.reports,
    allowedUsers: ["user"],
    title: "Reports",
  },
];

const Sidebar: React.FunctionComponent<Props> = () => {
  const classes = useSidebarStyles();
  const theme = useTheme<BasicTheme>();
  const userData = useAppSelector(({ userStore }) => {
    return userStore.userData;
  });
  const expand = useAppSelector(
    ({ sidebarStore }) => sidebarStore.sidebarExpand,
  );
  const dispatch = useAppDispatch();

  const dimension = useWindowDimensions();

  const login = useLogin();

  React.useEffect(() => {
    dispatch(toggleSidebar(dimension.width > widthBreakpoints[2]));
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (!userData) {
      login();
    }
  }, [userData, login]);

  return (
    <div className={[classes.container, expand ? "expand" : ""].join(" ")}>
      <div
        className={[classes.header, expand ? "expand" : "shorten"].join(" ")}
      >
        <img
          src={expand ? rapidsense_long : rapidsense_notext}
          alt="logo"
          style={{ transform: expand ? "scale(0.75)" : "" }}
        />
      </div>
      {/* Logo Container */}
      <div
        className={[classes.logoContainer, expand ? "expand" : ""].join(" ")}
      >
        <div
          className={[classes.sidebarLogoWrapper, expand ? "expand" : ""].join(
            " ",
          )}
        >
          <img
            alt="logo rapidsense"
            src={logo_datacakraWhite}
            className={[classes.sideBarLogo, expand ? "expand" : ""].join(" ")}
          />
        </div>
        {expand && (
          <>
            <span
              style={{
                color: "white",
                fontSize: "1.25rem",
                fontWeight: 700,
                letterSpacing: "0.025rem",
              }}
            >
              {userData?.username}
            </span>
            <span
              style={{
                color: theme.gray,
                fontSize: "1rem",
              }}
            >
              {userData?.companyName}
            </span>
            <span style={{ color: theme.gray, fontSize: "1rem" }}>
              {userData?.usertypeName}
            </span>
          </>
        )}
      </div>
      {/* Navigations Container */}
      <StyledNavigationItemWrapper>
        {nav.map((props) => {
          return <SidebarItem key={props.title} {...props} />;
        })}
      </StyledNavigationItemWrapper>
      <div
        className={[classes.footer, expand ? "expand" : ""].join(" ")}
        onClick={() => dispatch(toggleSidebar())}
      >
        {expand ? <BsChevronLeft /> : <BsChevronRight />}
      </div>
    </div>
  );
};

export default Sidebar;
