import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import {
  AGateway,
  AGatewayWithSubLocation,
  GatewayObj,
} from "../../api/gatewayAPI";
import { convertToEncodedURL } from "../../assets/usefulFunctions";

export const gatewayStoreAPI = createApi({
  reducerPath: "gatewayStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/gateway`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["GatewayList"],
  endpoints: (builder) => {
    return {
      getGateways: builder.query<
        AGatewayWithSubLocation[],
        Partial<GatewayObj>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<AGatewayWithSubLocation[]>,
        ) => response.data,
        providesTags: (result) =>
          result
            ? [
                ...result.map(
                  ({ id }) => ({ type: "GatewayList", id } as const),
                ),
                { type: "GatewayList", id: "LIST" },
              ]
            : [{ type: "GatewayList", id: "LIST" }],
      }),

      createGateway: builder.mutation<BackendDataShape<AGateway>, GatewayObj>({
        query: (obj) => {
          return {
            url: "/create",
            body: obj,
          };
        },
        invalidatesTags: ["GatewayList"],
      }),

      updateGateway: builder.mutation<
        BackendDataShape<string>,
        {
          /**
           * gateway Id
           */
          id: number;
          data: Partial<GatewayObj>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/update/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: (_, __, payload) => [
          { type: "GatewayList", id: payload.id },
        ],
      }),

      deleteGateway: builder.mutation<
        BackendDataShape<string>,
        {
          /**
           * gateway Id
           */
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: (_, __, payload) => [
          { type: "GatewayList", id: payload.id },
        ],
      }),
    };
  },
});

export const {
  useGetGatewaysQuery,
  useCreateGatewayMutation,
  useUpdateGatewayMutation,
  useDeleteGatewayMutation,

  util: { resetApiState: resetGatewayStoreAPI },
} = gatewayStoreAPI;
