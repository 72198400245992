import React from "react";
import { IconType } from "react-icons";
import { BsChevronDown } from "react-icons/bs";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { KnownUsertype } from "../../api/credentialsAPI/usertype";
import { BasicTheme, COLORS } from "../../assets/theme";
import { useAppSelector } from "../../hooks";
import useUsertype, { checkIfUserAllowed } from "../../hooks/auth/useUsertype";
import useMatchKiddo from "../../hooks/useMatchKiddo";

export interface KiddoElement {
  title: string;
  Icon?: IconType;
  shrinkTitle?: string;
  /**
   * if `undefined` or has length === 0, it will be allowed for everyone
   */
  allowedUsers?: KnownUsertype[];
  kiddoURL?: string;
}

export interface Props {
  title?: string;

  /**
   * if there is kiddo, clicking on parent item wont do any navigation
   */
  parentURL?: string;
  /**
   * if `undefined` or has length === 0, it will be allowed for everyone
   */
  allowedUsers?: KnownUsertype[];
  Icon?: IconType;
  kiddos?: KiddoElement[];
}

const useStyles = createUseStyles((theme: BasicTheme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
    },
    buttonStyle: {
      display: "flex",
      border: 0,
      alignItems: "center",
      padding: "0 1rem",
      backgroundColor: COLORS.black_1,
      color: "white",
      transition: "all .2s",
      "&.match": {
        backgroundColor: theme.sidebarSelected,
      },
      "&:hover": {
        backgroundColor: theme.sidebarBackgroundColor,
      },
      "&:focus": {
        outline: "none",
        boxShadow: "none",
      },
      "&.shrink": {
        justifyContent: "space-between",
        paddingBottom: "10px",
        paddingTop: "10px",
      },
    },
    buttonExpandStyle: {
      display: "flex",
      border: 0,
      alignItems: "center",
      padding: "0 1rem",
      backgroundColor: COLORS.black_1,
      color: "white",
      transition: "all .2s",
      "&:hover": {
        backgroundColor: theme.sidebarBackgroundColor,
      },
      "&:focus": {
        outline: "none",
        boxShadow: "none",
      },
      "&.shrink": {
        justifyContent: "space-between",
        paddingBottom: "10px",
        paddingTop: "10px",
      },
    },
    title: {
      flex: 1,
      margin: "1rem 0",
      textAlign: "left",
      overflowX: "hidden",
      "&.shrink": {
        textAlign: "center",
      },
    },
    chevron: {
      transform: "rotate(0deg)",
      transition: "transform .2s ease-in-out",
      "&.open": {
        transform: "rotate(-180deg)",
      },
    },
    kiddosContainer: {
      maxHeight: 0,
      transition: "all .3s",
      overflow: "hidden",
      "&.open": {
        maxHeight: "2000px", // need to be decided, scroll or view all?
      },
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    aKiddo: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      padding: "0.35rem 1rem",
      backgroundColor: COLORS.black_1,
      color: "white",
      position: "relative",
      transition: "all .2s",
      "&:hover": {
        backgroundColor: theme.sidebarBackgroundColor,
      },
      "&.match": {
        backgroundColor: theme.sidebarSelected,
      },
    },
  };
});

const SidebarItem: React.FunctionComponent<Props> = (props) => {
  const { title, parentURL, Icon, allowedUsers, kiddos } = props;
  const usertype = useUsertype() ?? "";
  const [open, setOpen] = React.useState<boolean>(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const { match, matchKiddo } = useMatchKiddo({ kiddos, parentURL });
  const isExpanded = useAppSelector(
    ({ sidebarStore }) => sidebarStore.sidebarExpand,
  );

  const listedKiddos = React.useMemo(() => {
    if (kiddos && kiddos.length) {
      let allowedKiddos: KiddoElement[] = [];
      kiddos.forEach((kid) => {
        const isKidAllowed = checkIfUserAllowed(usertype, kid.allowedUsers);
        if (isKidAllowed) {
          allowedKiddos.push(kid);
        }
      });
      return allowedKiddos;
    }
    return undefined;
  }, [kiddos, usertype]);

  const allowed = React.useMemo(() => {
    const isParentAllowed = checkIfUserAllowed(usertype, allowedUsers);
    if (listedKiddos && listedKiddos.length) {
      return isParentAllowed && listedKiddos.length > 0;
    }
    return isParentAllowed;
  }, [allowedUsers, usertype, listedKiddos]);

  return allowed ? (
   /**
   * @todo buatkan sidebar item ini menjadi sebuah `Link` atau `a`, sehingga menjadi benar secara semantik
   * sehingga ketika item ini diklik kanan, kita bisa buka di new tab.
   */
    <div className={[classes.container].join(" ")}>
      {!kiddos || kiddos.length === 0 ? (
        <button
          className={[
            classes.buttonStyle,
            open && "open",
            match && "match",
            !isExpanded && "shrink",
          ].join(" ")}
          onClick={(e) => {
            e.stopPropagation();
            setOpen((val) => !val);
            if (parentURL) {
              navigate(parentURL);
            }
          }}
        >
          <span
            style={{
              marginRight: isExpanded ? "8.5px" : 0,
              flexBasis: "1rem",
            }}
          >
            {Icon ? <Icon /> : []}
          </span>
          {isExpanded && (
            <span
              className={[classes.title, !isExpanded ? "shrink" : ""].join(" ")}
            >
              <p style={{ margin: 0, width: "max-content" }}>{title}</p>
            </span>
          )}
        </button>
      ) : (
        <button
          className={[
            classes.buttonExpandStyle,
            open && "open",
            !isExpanded && "shrink",
          ].join(" ")}
          onClick={(e) => {
            e.stopPropagation();
            setOpen((val) => !val);
          }}
        >
          <span
            style={{
              marginRight: isExpanded ? "8.5px" : 0,
              flexBasis: "1rem",
            }}
          >
            {Icon ? <Icon /> : []}
          </span>
          {isExpanded && (
            <span
              className={[classes.title, !isExpanded ? "shrink" : ""].join(" ")}
            >
              <p style={{ margin: 0, width: "max-content" }}>{title}</p>
            </span>
          )}
          {listedKiddos && listedKiddos.length ? (
            <span
              style={{
                flexBasis: "1rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <BsChevronDown
                className={[classes.chevron, open ? "open" : ""].join(" ")}
              />
            </span>
          ) : (
            []
          )}
        </button>
      )}
      {listedKiddos && listedKiddos.length ? (
        <div
          className={[classes.kiddosContainer, open ? "open" : ""].join(" ")}
        >
          {listedKiddos.map(({ title, shrinkTitle, kiddoURL }, i) => {
            return (
              <div
                className={[
                  classes.aKiddo,
                  matchKiddo === kiddoURL ? "match" : "",
                ].join(" ")}
                key={`${i}-${title}`}
                onClick={(e) => {
                  e.stopPropagation();
                  let url = `${kiddoURL}`;
                  navigate(url);
                }}
              >
                <span
                  className={[classes.title, !isExpanded ? "shrink" : ""].join(
                    " ",
                  )}
                  style={{
                    marginLeft: isExpanded ? "2rem" : 0,
                  }}
                >
                  <p
                    style={{
                      margin: isExpanded ? 0 : "auto",
                      width: "max-content",
                    }}
                  >
                    {isExpanded ? title : shrinkTitle}
                  </p>
                </span>
              </div>
            );
          })}
        </div>
      ) : (
        []
      )}
    </div>
  ) : (
    <></>
  );
};

export default SidebarItem;
