import { createUseStyles } from "react-jss";
import { BasicTheme, COLORS } from "../../assets/theme";

const useSidebarStyles = createUseStyles((theme: BasicTheme) => {
  return {
    container: {
      width: "50px",
      backgroundColor: COLORS.black_1,
      display: "flex",
      flexDirection: "column",
      minHeight: 0,
      maxHeight: "100vh",
      transition: "width 250ms ease-in-out",

      "&.expand": {
        width: "200px",
      },
    },
    header: {
      backgroundColor: COLORS.black_2,
      flexBasis: theme.barheight,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      transition: "all .2s",
      "&.expand": {
        padding: "1.25rem 0",
      },
      "&.shorten": {
        padding: "1.25rem 0",
      },
      "& > img": {
        height: `calc(${theme.barheight} - 5px)`,
      },
      "&.shorten > img": {
        width: "25px",
      },
    },
    logoContainer: {
      color: "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textTransform: "capitalize",
      padding: "4rem 0",
      gap: 4,
    },
    logoutButton: {
      border: 0,
      borderRadius: "1rem 0 0 1rem",
    },
    navItem: {
      paddingLeft: "1rem",
      "&:not(:first-child)": {
        marginTop: "15px",
      },
      "&:not(:last-child)": {
        marginBottom: "15px",
      },
      "&> button": {
        padding: ".5rem",
        borderRadius: "1rem 0 0 1rem",
      },
    },
    logoutContainer: {
      flexBasis: theme.barheight,
      display: "flex",
      flexDirection: "column",
      marginLeft: "1rem",
    },
    footer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: COLORS.white,
      paddingTop: "15px",
      paddingBottom: "15px",
      backgroundColor: COLORS.black_2,
      cursor: "pointer",
      fontWeight: "600",

      "&:hover": {
        opacity: 0.95,
      },
    },
    sidebarLogoWrapper: {
      marginBottom: "8px",
    },
    sideBarLogo: {
      transition: "all .2s",
      width: "3.5rem",
      "&.expand": {
        width: "5rem",
      },
    },
  };
});

export default useSidebarStyles;
